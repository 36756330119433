import axios from 'axios'
import ElementUI from "element-ui";

const http = axios.create({
    timeout: 1000 * 30,
    withCredentials: true,
    responseType: 'blob',
    headers: {
        'Content-Type': 'application/json; charset=utf-8'
    }
})

/**
 * 请求拦截
 */
http.interceptors.request.use(config => {
    // 请求头带上token
    config.headers['token'] = localStorage.getItem("oatoken") || '';
    return config
}, error => {
    return Promise.reject(error)
})

/**
 * 请求地址处理
 * @param {*} actionName action方法名称
 */
const adornUrl = (actionName) => {
    return "/api" + actionName;
}

/**
 * 下载文件
 * @param url 地址
 * @param data 数据
 * @param fileName 文件名
 * @param success 成功处理函数 200为成功
 * @param error 失败处理函数 非200为失败
 */
const httpPostDownload = (url, fileName,  success, error) => {
    http({
        url: adornUrl(url),
        method: 'get'
    }).then((res) => {
        const { data, headers } = res;
        //获取内容类型
        let contentType = headers['content-type'];
        //检测为json串
        if(contentType === 'application/json'){
            if(error && error.constructor === Function){
                error(data);
            } else {
                ElementUI.Message.error(data.msg);
            }
            return;
        } else {
            if(success && success.constructor === Function){
                success(data);
            }
        }
        fileName = fileName ? fileName : headers['content-disposition'].replace(/\w+;filename=(.*)/, '$1');
        // 此处当返回json文件时需要先对data进行JSON.stringify处理，其他类型文件不用做处理
        //const blob = new Blob([JSON.stringify(data)], ...)
        //创建blob对象
        const blob = new Blob([data], {type: contentType});
        let link = document.createElement('a');
        let body = document.querySelector('body');
        link.href = window.URL.createObjectURL(blob); // 创建一个下载文件的URL，它指向blob，因为Blob对象在之前在接收后端发来的文件流信息。因为是Blob对象，所以不会跳转页面
        // 以后还有新增的东西的话再加
        link.download = decodeURI(fileName); // 自己制定下载文件的文件名
        // 兼容火狐浏览器
        // 让这个a标签不可见
        link.style.display = 'none';
        body.appendChild(link);
        // 创建了新的a标签之后模拟点击事件，开始传输文件
        link.click();
        // 下载完成之后，移除按钮，垃圾回收，减少页面内存消耗
        body.removeChild(link);
        // 移除之前使用createObjectURL创建的URL，垃圾回收
        window.URL.revokeObjectURL(link.href);
    }).catch((err) => {
         
    });
}

export {
    httpPostDownload
}
