<template>
  <!-- 发票申请-->
  <div class="mine-order">
    <div class="mine-title">全部证书</div>
    <el-table :header-cell-style="getRowClass" :data="tableData" border style="width: 100%;height: 640px;overflow: auto;" v-loading="tableDataLoading">
      <!-- <el-table-column type="expand">
        <template slot-scope="props">
          <div style="margin: 30px 50px;border: 0.5px solid #EBEEF5;">
            <div style="display: flex;font-size: 18px;font-weight: 600;color: #909399;">
              <span style="flex: 1;text-align: center;border: 0.5px solid #EBEEF5;padding: 5px 0px;">课程名</span>
              <span style="flex: 0.5;text-align: center;border: 0.5px solid #EBEEF5;padding: 5px 0px;">价格</span>
              <span style="flex: 0.5;text-align: center;border: 0.5px solid #EBEEF5;padding: 5px 0px;">课时</span>
            </div>
            <div style="display: flex;" v-for="item in props.row.details.courseMsgList">
              <span style="flex: 1;text-align: center;border: 0.5px solid #EBEEF5;padding: 5px 0px;">
                {{ item.name }}
              </span>
              <span style="flex: 0.5;text-align: center;border: 0.5px solid #EBEEF5;padding: 5px 0px;">
                {{ item.price }}
              </span>
              <span style="flex: 0.5;text-align: center;border: 0.5px solid #EBEEF5;padding: 5px 0px;">
                {{ item.creditHours }}
              </span>
            </div>
          </div>
        </template>
      </el-table-column> -->
      <el-table-column border label="详情" type="expand" width="60">
        <template slot-scope="props"> 
          <div style="margin: 30px 60px;">
            <el-table border :data="props.row.details.courseMsgList">
              <el-table-column align="center" prop="name" label="课程名">
              </el-table-column>
              <el-table-column align="center" prop="price" label="价格" width="150">
              </el-table-column>
              <el-table-column align="center" prop="creditHours" label="学时" width="150">
              </el-table-column>
            </el-table>
          </div>
        </template>
      </el-table-column>

      <el-table-column prop="certificate.name" label="证书名"  align="center">
      </el-table-column>
      <el-table-column prop="certificate.declareLevel" label="等级" width="100" align="center">
      </el-table-column>
      <el-table-column prop="details.totalCreditHours" label="学时" width="100" align="center">
      </el-table-column>
      <el-table-column prop="certificate.yearName" label="年份" width="100" align="center">
      </el-table-column>
      <el-table-column label="下载次数" width="100" align="center">
        <template slot-scope="scope">
          {{ scope.row.printNum ? scope.row.printNum : '0' }}
        </template>
      </el-table-column>
      <el-table-column align="center" label="操作" width="120">
        <template slot-scope="scope">
          <el-button  type="text" size="small" @click="printCertificate(scope.row)">
              下载证书
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <!--分页组件-->
    <el-pagination background @size-change="sizeChangeHandle" @current-change="currentChangeHandle"
      :current-page="page.pageIndex" :page-sizes="[10, 20, 50, 100]" :page-size="page.pageSize" :total="page.totalPage"
      layout="total, prev, pager, next, jumper" style="text-align: center;">
    </el-pagination>
    <my-dialog title="提示" :visible.sync="deleteFlag" @before-close="refreshLX" width="363px">
      <div class="message">删除之后，相关学时也将对应减少，是否确认删除？</div>

      <el-row>
        <el-col class="flex_end_j">
          <div class="flex_cancel" @click="showFlag = false">取消</div>
          <div class="flex_confirm" @click="removeItem">确定</div>
        </el-col>
      </el-row>
    </my-dialog>
  </div>
</template>

<script>
import { httpGet } from "@/utils/httpRequest";
import { httpPostDownload } from "@/utils/httpDownload";
export default {
  name: "MineCert",
  data() {
    return {
      tableDataLoading: true,
      activeNames: ['1'],
      activeName: "first",
      myOrder: {
        records: [],
      },
      moveFlag: false,
      deleteFlag: false,
      tableData: [
        // {
        //   createTime: "2022-10-05",
        //   outTradeNo: "02934834",
        //   orderDetailList: [
        //     {
        //       imgUrl: "234",
        //       skuName: "证书标题证书标题证书标题",
        //       year: "2022",
        //       money: "391.00",
        //       status: "等待付款",
        //       totalAmount: "234",
        //     },
        //   ],
        // },
      ],
      BillData: [
        // {
        //   title: "订单标题订单标题订单标题订单标题",
        //   price: "￥391.00",
        //   time: "2022/10/07 08:04",
        // },
      ],
      //分页对象
      page: {
        //当前页
        pageIndex: 1,
        //每页显示数据
        pageSize: 5,
        //总页数
        totalPage: 0,
      },
    };
  },
  methods: {
    // 获取证书列表
    getCertList() {
      this.tableDataLoading = true;
      httpGet("/app/app-user-certificate/list",
        {
          userId: localStorage.getItem('userId'),
          size: this.page.pageSize,
          index: this.page.pageIndex,
        }, (res) => {
          // console.log(res)
          // 分页总页数赋值
          this.page.totalPage = res.data.total;
          let tableData = res.data.records
          // console.log(res.data)
          tableData.forEach(index => {
            index.created = index.created.split("T")[0]
            // index.tag = JSON.parse(index.tag)
            index.details = JSON.parse(index.details)
            // console.log(index.details)
          })
          this.tableData = tableData; 
          // console.log(this.tableData)
          this.tableDataLoading = false;
        }, (err) => {
          // console.log(err)
        })
    },
    // 打印证书
    printCertificate(data) {
      // console.log(data)
      this.tableDataLoading = true;
      
      httpPostDownload("/app/app-user-certificate/export-pdf?userCertificateId=" + data.id, "证书.pdf"
        , (res) => {
          // window.open()
          // console.log(res)
          this.getCertList()
        }, (err) => {
          // this.$message.error("请先进行用户认证")
          // console.log(err)
          throw err
        })
    },
    handleChange(val) {
      // console.log(val);
    },
    // 每页数
    sizeChangeHandle(val) {
      this.page.pageSize = val;
      this.page.pageIndex = 1;
    },

    // 当前页
    currentChangeHandle(val) {
      this.page.pageIndex = val;
      this.getCertList();
    },
    removeItem() { },
    refreshLX() {
      this.deleteFlag = false;
    },
    // 删除当前订单
    deleteItem() {
      this.deleteFlag = true;
    },
    // 申请开票
    clickApply() {
      this.$router.push({ name: "BillHandle", params: { id: 1 } });
    },
    // 详情页
    clickDetails(row) {
      this.$router.push({ name: "Bill", params: { id: row.id } });
    },
    // 撤回申请
    clickWithdraw() {
      this.$router.push({ name: "Bill", params: { id: 4 } });
    },
    // tab切换
    handleClick() { },
    // 表格数据多选
    handleSelectionChange() { },
    //表头颜色
    getRowClass() {
      return "background:#EBECF0;color:#3E454D;";
    },
    //表体样式
    getCellStyle() {
      return (
        "font-size: 0.875rem;\n" +
        "font-family: PingFang SC-Medium, PingFang SC;\n" +
        "font-weight: 500;\n" +
        "color: #3E454D;"
      );
    },
  },
  mounted() {
    this.getCertList();
  }

};
</script>

<style lang="scss" scoped>
.mine-order {
  padding: 0 32px;
  background: #ffffff;
  // box-shadow: 0 0.125rem 0.25rem 0 rgba(3, 27, 78, 0.06);
  border-radius: 10px;
  height: 49.5rem;
  overflow: auto;

  .mine-title {
    width: 100%;
    text-align: left;
    height: 64px;
    line-height: 64px;
    font-size: 15px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: bold;
    color: #000000;
    border-bottom: 2px solid #E4E7ED;
    margin-bottom: 20px;
    // background-color: #1371e7;
  }

  .mine-more {
    width: 100%;
    background-color: #667280;
  }

  .more-items {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 174px;
    width: 100%;
    background: #f5f8fa;
    // background-color: #ebecf0;

    .item {
      width: 100%;
      box-sizing: border-box;
      padding: 12px 16px;
      display: flex;
      border: 1px solid #edeff2;

      img {
        display: flex;
        width: 110px;
        height: 61px;
        border-radius: 1px 1px 0px 0px;
      }

      .item-right {
        padding-left: 10px;
        display: flex;
        flex-direction: column;
        font-size: 14px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #667280;

        .right-top {
          padding-bottom: 10px;
          font-size: 14px;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;
          color: #3e454d;
        }

        span {
          display: block;
          height: 18px;
          text-align: left;
        }
      }
    }
  }

  .message {
    font-size: 1.125rem;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.85);
    line-height: 1.5rem;
    text-align: left;
    padding: 0.75rem 1.5rem 3rem 2rem;
  }

  .message::before {
    content: "";
    display: inline-block;
    width: 1rem;
    height: 1rem;
    background: url("~@/assets/img/pre/icon-tip-1.png");
    background-size: 100% 100%;
    margin-right: 0.25rem;
  }

  .flex_end_j {
    display: flex;
    justify-content: flex-end;
    padding-right: 1rem;
    height: 3.25rem;
    border-top: 0.0625rem solid #edeff2;
    align-items: center;

    .flex_cancel {
      width: 15.97796%;
      height: 2rem;
      background: #ffffff;
      border-radius: 10px;
      opacity: 1;
      border: 0.0625rem solid #e6e8eb;
      font-size: 0.8125rem;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #3e454d;
      line-height: 2rem;
      margin-right: 0.625rem;
      cursor: pointer;
    }

    .flex_confirm {
      width: 15.97796%;
      height: 2rem;
      background: #1371e7;
      box-shadow: 0px 0.25rem 0.375rem 0px rgba(19, 113, 231, 0.08);
      border-radius: 10px;
      font-size: 0.8125rem;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      line-height: 2rem;
      cursor: pointer;
    }
  }

  .mine-order-head {
    background: #ebecf0;
    height: 2.125rem;
    line-height: 1.875rem;
    font-size: 0.75rem;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #3e454d;

    th:nth-child(n + 2) {
      width: 9rem;
      text-align: center;
    }
  }

  .orders {
    margin-top: 1.25rem;
    line-height: 2.75rem;
    height: 38rem;

    .mine-order-item {
      font-size: 0.75rem;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: #3e454d;
      width: 100%;
      margin-top: 1.25rem;

      .btn {
        display: inline-block;
        width: 5.25rem;
        height: 2rem;
        background: #ffffff;
        border-radius: 10px;
        opacity: 1;
        border: 0.0625rem solid #1371e7;
        font-size: 0.8125rem;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #1371e7;
        line-height: 2rem;
        cursor: pointer;
      }

      .btn:hover {
        background: #1371e7;
        box-shadow: 0 0.25rem 0.375rem 0 rgba(19, 113, 231, 0.08);
        border-radius: 10px;
        color: #ffffff;
      }

      .typographic {
        display: flex;
        align-items: center;

        .typographic-div {
          height: 2.875rem;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          margin-left: 0.625rem;

          .title {
            height: 1.125rem;
            font-size: 0.875rem;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 500;
            color: #3e454d;
            line-height: 1.125rem;
          }

          .year {
            height: 1.125rem;
            font-size: 0.875rem;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 500;
            color: #667280;
            line-height: 1.125rem;
          }
        }

        span {
          display: block;
        }
      }

      .order-title {
        padding-left: 0.75rem;
      }

      th {
        background: #edeff2;
      }

      table {
        border-collapse: collapse;
      }

      td {
        height: 5rem;
      }

      table,
      th,
      td {
        border: 0.0625rem solid #ebeef5;
      }

      td:nth-child(n + 2) {
        width: 9rem;
        text-align: center;
      }

      .delete {
        font-size: 0.75rem;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        display: flex;
        align-items: center;
        padding-right: 0.625rem;
      }

      .delete::before {
        content: "";
        display: inline-block;
        width: 1rem;
        height: 1rem;
        background: url("~@/assets/img/pre/icon-delete.png");
        background-size: 100% 100%;
        margin-right: 0.625rem;
      }
    }
  }

  table {
    border-collapse: collapse;
  }

  .apply {
    width: 5.25rem;
    height: 2rem;
    background: #1371e7;
    box-shadow: 0 0.25rem 0.375rem 0 rgba(19, 113, 231, 0.08);
    border-radius: 10px;
    font-size: 0.8125rem;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    line-height: 2rem;
    text-align: center;
    margin-top: 1.25rem;
  }

  // 复选康
  ::v-deep .el-checkbox__inner {
    width: 1rem;
    height: 1rem;
  }

  // tabs
  ::v-deep .el-tabs {
    background-color: #ffffff;
  }

  ::v-deep .el-tabs__active-bar {
    background-color: #1371e7;
    height: 0.25rem;
  }

  ::v-deep .el-tabs__item.is-active {
    font-weight: bolder;
    color: #000000;
  }

  ::v-deep .el-tabs__item {
    height: 4rem;
    line-height: 4rem;
    font-size: 0.9375rem;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    padding: 0 2.5rem;
    color: #667280;
  }

  ::-webkit-scrollbar {
    display: none;
    /* Chrome Safari */
  }

  ::v-deep .el-tabs__content {
    height: 40.0625rem;
    margin-top: 1.25rem;
    overflow: hidden;
    overflow-y: scroll;
    text-align: left;
  }

  // 分页样式
  // ::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
  //   color: #FFF;
  //   width: 66px;
  //   height: 36px;
  //   line-height: 36px;
  //   background: #1371E7;
  //   border-radius: 2px 2px 2px 2px; 
  //   opacity: 1;
  //   border: 1px solid #EDEFF2;
  // }

  // ::v-deep .el-pagination.is-background .btn-next,
  // ::v-deep .el-pagination.is-background .btn-prev,
  // ::v-deep .el-pagination.is-background .el-pager li {
  //   width: 66px;
  //   height: 36px;
  //   background: #FFFFFF;
  //   border-radius: 2px 2px 2px 2px;
  //   opacity: 1;
  //   border: 0.0625rem solid #EDEFF2;
  //   border-radius: 2px;
  // }
}
</style>
